/**
 * @author CHEN GUANGPENG
 * @date 2019/12/17
 */
import React from 'react';
import styles from '../assets/css/contact.module.css';
import ContactItem from '../components/contactItem/index';
import { Map, Marker } from 'react-amap';
import Layout from '../components/layout/index';

const ContactMsg = () => {
  const { msgContainer } = styles;
  return (
    <div className={msgContainer}>
      <ContactItem
        img="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/contact_phone.png"
        title="电话"
        subTitle="TELEPHONE"
        introduction="010-59897327"
      ></ContactItem>
      <ContactItem
        img="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/contact_wechat.png"
        title="公众号"
        subTitle="WeChat Official Account"
        introduction="Locals-home"
      ></ContactItem>
      <ContactItem
        img="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/contact_email.png"
        title="邮箱"
        subTitle="E-MAIL"
        introduction="guangzhoulocals@localhome.com.cn"
      ></ContactItem>
      <ContactItem
        img="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/contact_address.png"
        title="地址"
        subTitle="Address"
        introduction="海珠区红卫新村29号,UP+智谷A3栋 (邮编:510430)"
      ></ContactItem>
    </div>
  );
};

export default () => {
  const { banner, map, locationIcon } = styles;
  const markerPosition = {
    longitude: 113.329202,
    latitude: 23.086633,
  };
  return (
    <Layout>
      <div className={banner}></div>
      <ContactMsg></ContactMsg>
      <div className={map}>
        <Map zoom={15} center={markerPosition}>
          <Marker position={markerPosition}>
            <img
              className={locationIcon}
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/location.png"
            ></img>
          </Marker>
        </Map>
      </div>
    </Layout>
  );
};
