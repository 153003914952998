/**
 * @author CHEN GUANGPENG
 * @date 2019/12/17
 */
import React from 'react';
import styles from './index.module.css';

export default props => {
  const {
    contactItem,
    iconContainer,
    content,
    title: titleStyle,
    introduction: introductionStyle,
  } = styles;
  const { img, title, subTitle, introduction } = props;
  return (
    <div className={contactItem}>
      <div className={iconContainer}>
        <img src={img}></img>
      </div>
      <div className={content}>
        <h3 className={titleStyle}>
          {title}
          <br />
          {subTitle}
        </h3>
        <p className={introductionStyle}>{introduction}</p>
      </div>
    </div>
  );
};
